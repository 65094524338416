const columns = (t) => [
  {
    key: 'orderNo',
    label: t('orderNo'),
    sortable: false,
    span: 2,
    align: 'center',
  },
  {
    key: 'ediOrderNo',
    label: t('internalOrderNo'),
    sortable: false,
    span: 2,
    align: 'center',
  },
  {
    key: 'admin',
    label: t('createdBy'),
    sortable: false,
    span: 2,
    align: 'center',
  },
  {
    key: 'createdAt',
    label: t('orderCreatedAt'),
    sortable: true,
    align: 'center',
    span: 3,
  },
  {
    key: 'estimatedDelivery',
    label: t('estimatedDeliveryDate'),
    sortable: false,
    span: 2,
    align: 'center',
  },
  {
    key: 'status',
    label: t('status'),
    sortable: true,
    span: 2,
    align: 'center',
  },
  {
    key: 'price',
    label: t('totalPrice'),
    sortable: false,
    span: 2,
    align: 'center',
  },
];

export default columns;
